<template>
  <div class="award-detail">
    <div class="form-content content">
      <ActionsForPreview
        :loading="loading"
        @edit="editAward"
        @delete="openModal"
        :isEditEnabled="false"
      />
      <div v-if="ready">
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card" v-if="ready">
              <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ texts.previewPage.title }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="basicIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="basicIsCollapsed" :duration="400">
                <CCardBody>
                  <ZqFieldsView :texts="texts.previewPage" :formData="formData" :modelFields="modelFields" />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>
        <CRow v-if="isAddTag">
          <CCol col="12">
            <CIInput
              v-model="tag"
            />
            <CButton @click="addTag" color="primary">
              Add tag
            </CButton>
            <br/>
            <hr/>
            <br/>
            <multiselect
                v-model="selectedStatus"
                :options="['Completed', 'Expired', 'Declined']"
                :taggable="false"
                :hide-selected="true"
                :block-keys="['Tab', 'Enter']"
                :multiple="false"
                class="custom-multi-select zq--multiselect"
            />
            <CButton @click="setStatus" color="primary">
              Set Status
            </CButton>
          </CCol>
        </CRow>
      </div>
      <PreviewSpiner v-else />
    </div>
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="texts.deleteMessage"
      :title="texts.deleteTitle"
      @doFunction="deleteAward"
      v-on:toggle-modal="deleteModal = false"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import ActionsForPreview from '@/shared/components/ActionsForPreview';
  import ZqFieldsView from '@/shared/components/ZqFieldsView';
  import ClCardArrow from '@/shared/UI/ClCardArrow';
  import { pageConfig } from '@/config';
  import PreviewSpiner from '@/shared/UI/Spiner';
  import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
  import Modal from '@/shared/UI/Modal';
  import { awardsTexts } from '@/config/pageTexts/awards.json';
  import fields from '@/generated/ziqni/store/modules/awards/fields';
  import axios from 'axios';

  export default {
    name: 'AwardDetails',
    components: {
      PreviewSpiner,
      ActionsForPreview,
      ZqFieldsView,
      Modal,
      ClCardArrow,
    },
    data() {
      return {
        model: 'award',
        entityId: this.$route.params.id,
        isAddTag: false,
        tag: '',
        selectedStatus: '',
        formData: {
            id: '',
            spaceName: '',
            created: '',
            memberId: '',
            rewardId: '',
            rewardType: '',
            rewardRank: '',
            rewardValue: '',
            delay: '',
            entityType: '',
            entityId: '',
            pointInTime: '',
            period: '',
            tags: [],
            metadata: {},
            constraints: [],
            claimedTimestamp: ''
        },
        texts: {
          ...awardsTexts,
        },
        basicIsCollapsed: true,
        inboxIsCollapsed: false,
        deleteModal: false,
        itemsPerPage: pageConfig.itemsPerPage,
        query: {},
        sortBy: pageConfig.sortBy,
        page: pageConfig.page,
        modelFields : {
          ...fields,
        },
        ready: false,
        schedulingData: null,
        rewardModels: ['achievement'],
        ruleModels: ['achievement'],
      };
    },
    computed: {
      ...mapGetters('awards', [
        'success',
        'message',
        'awards',
        'loading',
        'award'
      ]),
      ...mapGetters('theme', ['theme']),
    },
    created() {
      this.initialize();
    },
    methods: {
      ...mapActions('awards', [
        'handleGetAwards_item',
        'handleDeleteAwards',
      ]),
      async initialize() {
        await this.handleGetAwards_item([this.$route.params.id],1,0).then((data) => {
          routerBreadcrumbs(this.$router.currentRoute, {
            id: data[0].id,
          });
          if (data[0].scheduling) {
            this.schedulingData = data[0].scheduling;
          }

          this.ready = true;
        });
      },
      editAward() {
        this.$router.push({
          name: 'EditAward',
          params: {
            id: this.formData.id,
            name: this.formData.name,
          },
        });
      },
      deleteAward() {
        this.deleteModal = false;
        this.handleDeleteAwards({
          idArray: [this.entityId],
          queryData: this.query,
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }).then(() => this.$router.push({ name: 'Awards' }).catch((e) => {
            console.log(e.message);
           }));
      },
      openModal() {
        this.deleteModal = true;
      },
      addTag() {
        const token = localStorage.getItem('vue-token');
        const path = 'https://api.ziqni.com/awards';
        const request = [{
          id: this.$route.params.id,
          tags: [this.tag]
        }];

        axios.put(path, request, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
          .then(() => {
            console.log('Done')
          });
      },
      setStatus() {
        const token = localStorage.getItem('vue-token');
        const path = 'https://api.ziqni.com/awards/state';

        const request = [{
          id: this.$route.params.id,
          action: this.selectedStatus
        }];

        axios.put(path, request, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        })
            .then(() => {
              console.log('Done')
            });
      }
    },
    watch: {
      award: {
        deep: true,
        handler: function (val) {
          this.formData = val;
        },
      },
      awards: {
        deep: true,
        handler: function () {
          this.$router.push({ path: '/awards' }).catch((e) => {
            console.log(e.message);
          });
        },
      },
    },
  };
</script>
<style lang="scss">
  award-detail {
    height: 100%;
    flex: 2;
    .content {
      border-top: 1px solid var(--zq-content-border-color);
    }
    .form-content {
      height: calc(100% - 50px);
      background: var(--zq-sub-bg);
    }
    .lang-custom-button {
      width: 100%;
    }
  }

  .detail-label {
    @media (min-width: 420px) {
      max-width: 220px;
    }
  }

  .overview-label {
    font-style: italic;
    color: darkgray;
  }
</style>
